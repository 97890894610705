<template>
    <div class="steps">
        <div class="steps__wrapper">
            <h1 class="steps__title">
                Как это работает?
            </h1>
            <div class="steps__list">
                <div class="steps__item step">
                    <span class="step__icon step__doc-icon"></span>
                    <p class="step__title">
                        Заполните<br/>
                        анкету
                    </p>
                    <span class="step__num">1</span>
                </div>
                <div class="steps__item step">
                    <span class="step__icon step__clock-icon"></span>
                    <p class="step__title">
                        Выберите<br/>
                        сумму и срок
                    </p>
                    <span class="step__num">2</span>
                </div>
                <div class="steps__item step">
                    <span class="step__icon step__list-icon"></span>
                    <p class="step__title">
                        Получите<br/>
                        подтверждение
                    </p>
                    <span class="step__num">3</span>
                </div>
                <div class="steps__item step">
                    <span class="step__icon step__money-icon"></span>
                    <p class="step__title">
                        Получите<br/>
                        деньги
                    </p>
                    <span class="step__num">4</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './steps.scss'

export default {
    name: 'Steps'
}
</script>